import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import scrollTo from "gatsby-plugin-smoothscroll";
import fetchForm from "../../../helpers/fetchForm";
import HoneyPot from "../../../components/HoneyPot/HoneyPot";

const ContactSchema = Yup.object().shape({
  "your-message": Yup.string().required("The field is required"),
  "your-email": Yup.string()
    .email("The e-mail address is invalid.")
    .required("The field is required"),
  "your-phone": Yup.number().typeError("The phone number is invalid.")
});

const ContactForm = () => {
  const [message, setMessage] = useState({
    type: "",
    message: ""
  });

  const [checkboxSelected, setCheckboxSelected] = useState({
    checkboxSelected: false
  });

  return (
    <div>
      <Formik
        initialValues={{
          "your-email": "",
          "your-phone": "",
          "your-message": "",
          website: "",
          _wpcf7: "350",
          _wpcf7_version: "5.1.1",
          _wpcf7_locale: "en_US",
          _wpcf7_unit_tag: "wpcf7-f350-p11-o1",
          _wpcf7_container_post: "11"
        }}
        validationSchema={ContactSchema}
        onSubmit={(values, { resetForm }) => {
          const termsValue = document.getElementById("terms").checked;
          if (termsValue === true) return;
          // eslint-disable-next-line no-undef
          const url = `${REACT_APP_API_DOMAIN}wp-json/contact-form-7/v1/contact-forms/350/feedback`;
          fetchForm(values, url, resetForm).then(r => {
            setMessage(r);
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="wpcf7-form">
            <div className="form__input-contact">
              {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              <label htmlFor="your-email" id="emailFieldLabel">
                E-mail<span>*</span>
                <span className="wpcf7-form-control-wrap your-email">
                  <Field
                    name="your-email"
                    id="your-email"
                    type="email"
                    size="40"
                    aria-required="true"
                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                  />
                  {errors["your-email"] && touched["your-email"] ? (
                    <span role="alert" className="wpcf7-not-valid-tip">
                      {errors["your-email"]}
                    </span>
                  ) : null}
                </span>
              </label>
              {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            </div>
            <div className="form__input-contact">
              {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              <label htmlFor="your-phone" id="phoneFieldLabel">
                Phone number
                <span className="wpcf7-form-control-wrap your-phone">
                  <Field
                    name="your-phone"
                    id="your-phone"
                    type="text"
                    size="20"
                    aria-required="false"
                    className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                  />
                  {errors["your-phone"] && touched["your-phone"] ? (
                    <span role="alert" className="wpcf7-not-valid-tip">
                      {errors["your-phone"]}
                    </span>
                  ) : null}
                </span>
              </label>
              {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            </div>
            <div className="form__input-contact">
              {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
              <label htmlFor="your-message" id="messageFieldLabel">
                message<span>*</span>
                <span className="wpcf7-form-control-wrap your-message">
                  <Field
                    component="textarea"
                    name="your-message"
                    id="your-message"
                    size="40"
                    aria-required="true"
                    className="pcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                  />
                  {errors["your-message"] && touched["your-message"] ? (
                    <span role="alert" className="wpcf7-not-valid-tip">
                      {errors["your-message"]}
                    </span>
                  ) : null}
                </span>
              </label>
              {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            </div>
            <HoneyPot />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            {/* <p onClick={() => scrollTo("#contact")}> */}
            <div
              className={`wpcf7-response-output wpcf7-display-none ${message.type}`}
              id="wpcf7-response"
            >
              {message.message}
            </div>
            <form id="contactCheckboxForm" className="contactCheckboxForm">
              <label
                id="contactCheckboxLabel"
                htmlFor="contact-checkbox"
                className="contactCheckboxForm-CheckboxLabel"
              >
                <input
                  id="contact-checkbox"
                  type="checkbox"
                  onClick={() => setCheckboxSelected(!checkboxSelected)}
                />
                <span className="contact-checkmark" />
                <p>
                  I agree to the processing of my personal data in the process of communication with
                  PandaGroup. I can read about the details of the processing in the privacy policy.
                </p>
              </label>
            </form>
            <input
              type="submit"
              value="Send message"
              className="wpcf7-form-control wpcf7-submit btn__cta btn__cta--green btn--estimate"
              disabled={checkboxSelected}
            />
            <span className="ajax-loader" />
            {/* </p> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ContactForm;
