import React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/ContactLayout";
import renderHTML from "../helpers/renderHTML";
import SEO from "../Layout/Seo";
import Map from "../modules/Contact/Map";

import ContactForm from "../modules/Contact/components/ContactForm";
import ContactTimer from "../modules/Contact/components/ContactTimer";

import OpeningHours from "../modules/Contact/components/ContactOpeningHours";

const PageContact = props => {
  const contactPage = props.data.wordpressPage;
  const contactPageData = props.data.allWordpressAcfPages.edges;
  const metaTitle = contactPage.yoast.title || "";
  const metaDesc = contactPage.yoast.metadesc || "";
  const metaKeyWords = contactPage.yoast.metakeywords || "";

  // make data array more readable (collect real data)
  const contactPageDataPrepared = contactPageData.reduce((acc, edge) => {
    const {
      node: { acf }
    } = edge;

    if (acf !== null) {
      Object.keys(acf).map(item => {
        if (item !== null && acf[item] !== null) {
          acc[item] = acf[item];
        }

        return item;
      });
    }

    if (acc.node) delete acc.node;

    return acc;
  });

  return (
    <Layout>
      <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
      <section className="contact" id="contact">
        <div className="container">
          <div className="contact__wrapper">
            <div className="contact__half">
              <div className="contact__half__wrapper">
                <h3 className="contact__heading">Panda Group</h3>
                <div className="contact__content">
                  {contactPageDataPrepared !== null ? (
                    <div className="contact__item contact__address">
                      <div>
                        <strong>{contactPageDataPrepared.address}</strong>
                      </div>
                      <div dangerouslySetInnerHTML={renderHTML(contactPageDataPrepared.street)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="contact__item contact__email">
                    {contactPageDataPrepared !== null ? (
                      <a href={`mailto: ${contactPageDataPrepared.e_mail}`}>
                        {contactPageDataPrepared.e_mail}
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="contact__item contact__phone">
                    {contactPageDataPrepared !== null ? (
                      <a href={`tel: ${contactPageDataPrepared.phone}`}>
                        {contactPageDataPrepared.phone}
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="contact__block">
                    <h3 className="contact__heading">Opening hours</h3>
                    {/* <div
                      className="contact__item contact__time"
                      dangerouslySetInnerHTML={renderHTML(contactPageDataPrepared.openning_hours)}
                    /> */}
                    <OpeningHours />
                  </div>
                  <ContactTimer />
                </div>
              </div>
            </div>
            <div className="contact__half">
              <div className="contact__half__wrapper">
                <h3 className="contact__heading">Write us, we will answer within 24h!</h3>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <Map />
      </section>
    </Layout>
  );
};

export default PageContact;

export const pageQuery = graphql`
  query contactPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      yoast {
        title
        metakeywords
        metadesc
      }
    }
    allWordpressAcfPages {
      edges {
        node {
          id
          acf {
            address
            street
            e_mail
            phone
            openning_hours
            our_time
          }
        }
      }
    }
  }
`;
