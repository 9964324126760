import React from "react";

const HoneyPot = () => {
  return (
    <label className="terms-req required label-req" htmlFor="terms">
      This field is required
      <input className="terms-req required" type="checkbox" id="terms" name="terms" />
    </label>
  );
};

export default HoneyPot;
