import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import pandapin from "../../images/panda-pin.png";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: 52.45,
        lng: 16.95
      },
      zoom: 1
    };
  }

  render() {
    return (
      <div className="contact__map" id="contact-map" style={{ width: "100%", height: "100%" }}>
        <div className="map-wrapper">
          <div
            className="acf-map"
            style={{
              width: "100%",
              height: "655px",
              border: "#ccc solid 1px",
              margin: "20px 0 0 0"
            }}
          >
            <GoogleMapReact
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
              bootstrapURLKeys={{ key: "AIzaSyB3brDE4uJOzeZe-wM_v9dlNLEP0sQz6Ss", v: "3.31" }}
            >
              <Marker
                lat={52.4549914}
                lng={16.9560481}
                marker={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <img
                    src={pandapin}
                    alt="pandapin"
                    style={{
                      transform: "translate(-50%, -100%)"
                    }}
                  />
                }
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
